// BAYGRAPE API URL CONFIGURATIONS - ENVIRONMENT WISE
export var BG_CONFIG = {
  Local_API_Url: "https://localhost:5002/api/",
  Local_Identity_API_Url: "https://localhost:5003/api/",
  // Dev_API_Url: "https://ms-bg-apps-gateway-dev.azurewebsites.net/api/",
  Dev_API_Url: "https://bg-docsnow-api-dev.baygrape.com/api/",
  Dev_Identity_API_Url: "https://bg-docsnow-identity-api-dev.baygrape.com/api/",
  Qa_API_Url: "https://bg-docsnow-api-qa.baygrape.com/api/",
  Qa_Identity_API_Url: "https://bg-docsnow-identity-api-qa.baygrape.com/api/",
  Trial_API_Url: "https://trial-api.docsnow.io/api/",
  Trial_Identity_API_Url: "https://trial-identity-api.docsnow.io/api/",
  Prod_API_Url:
    "https://ms-bg-apps-gateway.azurewebsites.net.azurewebsites.net/api/",
  DEV_INDIA_Url: "https://ms-docsnow-india-gateway-dev.azurewebsites.net/api/",
  Prod_INDIA_Url:
    "https://ms-docsnow-india-gateway.azurewebsites.net.azurewebsites.net/api/",
  ContentType: "application/json",

  //dev url
  Dev_CMS_Url: "https://bg-docsnow-cms-dev.baygrape.com/",
  //Qa CMS url
  Qa_CMS_Url: "https://bg-docsnow-cms-qa.baygrape.com/",
  //Trial_CMS_Url
  Trial_CMS_Url: "https://trial-cms.docsnow.io/",
  // dev site key
  Dev_siteKey: "6Lc2IhspAAAAAEAqbx4wdUM2s1iDM3WmtU1juZ4c",
  // qa site key
  Qa_siteKey: "6LcqebYpAAAAAKHBLe1c1R7_JmJFsxGOJrt2RrvR",
};

// CREDIT.ORG API URL CONFIGURATIONS - ENVIRONMENT WISE
export var CREDIT_CONFIG = {
  Local_API_Url: "https://localhost:5003/api/",
  Dev_API_Url: "https://ms-creditorg-docservice-dev.azurewebsites.net/api/",
  Prod_API_Url: "https://ms-creditorg-docservice.azurewebsites.net/api/",

  ContentType: "application/json",
};

//LOGO/FAVICON/LOGO REDIRECTION LINK CONFIGURATION PATHS
export var CONFIG_CONSTANTS: any = {
  //notes display name (admin notes only)
  BG_CLIENT_NOTES_DISPLAY_NAME: "BayGrape",
  CREDIT_CLIENT_NOTES_DISPLAY_NAME: "credit.org",
  //home page logo
  BG_LOGO: "assets/images/bg_logo.png",
  CREDIT_LOGO: "assets/images/credit_org_logo.png",
  //login page logo
  BG_LOGO_TRANSPARENT: "assets/images/bg_logo_trnp.png",
  CREDIT_LOGO_TRANSPARENT: "assets/images/credit_org_logo_trnp.png",

  //favicon
  BG_FAVICON: "assets/icons/bg_favicon.png",
  CREDIT_FAVICON: "assets/icons/credit_org_favicon.png",
  DOCSNOW_FAVICON: "assets/images/favicon.svg",

  //logo link
  BG_LOGO_CLIENT_LINK: "https://www.baygrape.com",
  BG_LOGO_ADMIN_LINK: "https://docsnow.io/",
  CREDIT_LOGO_CLIENT_LINK: "https://credit.org",
  CREDIT_LOGO_ADMIN_LINK: "https://credit.org",

  // LOGO SIZES
  BG_LOGO_CLIENT_SIZE: "40px",
  BG_LOGO_ADMIN_SIZE: "40px",
  CREDIT_LOGO_CLIENT_SIZE: "40px",
  CREDIT_LOGO_ADMIN_SIZE: "40px",
  DOCSNOW_LOGO_ADMIN_SIZE: "20px",
  //Copyright Year
  BG_COPYRIGHT_YEAR: "2022",
  CREDIT_COPYRIGHT_YEAR: "2022",
  //Powered By
  BG_POWERED_BY: "Developed By Baygrape.",
  CREDIT_POWERED_BY: "Developed By Baygrape.",
  //App Name
  project_name: "DocsNow",
  DORONOW_LOGO: "assets/images/docsnow.svg",
  Image_Base_Url: "https://bgassets.blob.core.windows.net/",
  isSubscriptionNeeded: true,
  freeTrialSubscriptionCode: "2f257480-e168-4c61-974f-e0d29a68bf38",

  microsoftOfficeViewerUrl:
    "https://view.officeapps.live.com/op/embed.aspx?src=",
};

// API URL changes - BG_CONFIG/CREDIT_CONFIG
export var CONFIG = {
  Local_API_Url: BG_CONFIG.Local_API_Url,
  Local_Identity_API_Url: BG_CONFIG.Local_Identity_API_Url,
  Dev_API_Url: BG_CONFIG.Dev_API_Url,
  Dev_Identity_API_Url: BG_CONFIG.Dev_Identity_API_Url,
  Qa_API_Url: BG_CONFIG.Qa_API_Url,
  Qa_Identity_API_Url: BG_CONFIG.Qa_Identity_API_Url,
  Trial_API_Url: BG_CONFIG.Trial_API_Url,
  Trial_Identity_API_Url: BG_CONFIG.Trial_Identity_API_Url,
  Prod_API_Url: BG_CONFIG.Prod_API_Url,
  DEV_INDIA_Url: BG_CONFIG.DEV_INDIA_Url,
  Prod_INDIA_Url: BG_CONFIG.Prod_INDIA_Url,
  ContentType: BG_CONFIG.ContentType,
  isEnvironment: "DEV",
  CMS_Url: BG_CONFIG.Qa_CMS_Url,
};

// API URL - CLIENT ENVIRONMENT WISE
export var main_url: any = {
  api_url: CONFIG.Qa_API_Url,
  identity_api_url: CONFIG.Qa_Identity_API_Url,
  login_type: "USERNAME_PASSWORD", //---USERNAME_PASSWORD for JWT_Login and AD_LOGIN For AD_Login
};

//BAYGRAPE FOOTER SETTINGS
const BG_FOOTER_SETTINGS = {
  FOOTER1: {
    ICON: "fa fa-envelope-o",
    TEXT: "Questions regarding the documents? Contact sender",
    LINK: "#",
    DISPLAY: true,
  },
  FOOTER2: {
    ICON: "fa fa-question-circle",
    TEXT: "Support",
    LINK: "#",
    DISPLAY: true,
  },
  FOOTER3: {
    ICON: "fa fa-info-circle",
    TEXT: "Envelope Details",
    LINK: "#",
    DISPLAY: true,
  },
};

//CREDIT.ORG FOOTER SETTINGS
const CREDIT_FOOTER_SETTINGS = {
  FOOTER1: {
    ICON: "",
    TEXT: "",
    LINK: "#",
    DISPLAY: false,
  },
  FOOTER2: {
    ICON: "fa fa-question-circle",
    TEXT: "GLB Privacy Notice",
    LINK: "assets/pdf/GLB_Privacy_Notice_2021.pdf",
    DISPLAY: true,
  },
  FOOTER3: {
    ICON: "fa fa-info-circle",
    TEXT: "Privacy Policy",
    LINK: "https://credit.org/privacy-policy",
    DISPLAY: true,
  },
};

//APP SETTINGS FOR LOGO/FAVICON/FOOTER SETTINGS
// Note:- In case of change color theme, change index.html data data-theme='credit|baygrape'
export var APP_SETTINGS: any = {
  LOGO: CONFIG_CONSTANTS.DORONOW_LOGO,
  TRANSPARENT_LOGO: CONFIG_CONSTANTS.DORONOW_LOGO,
  FAVICON: CONFIG_CONSTANTS.DOCSNOW_FAVICON,
  FOOTER: BG_FOOTER_SETTINGS,
  LOGO_CLIENT_LINK: CONFIG_CONSTANTS.BG_LOGO_CLIENT_LINK,
  LOGO_ADMIN_LINK: CONFIG_CONSTANTS.BG_LOGO_ADMIN_LINK,
  LOGO_CLIENT_SIZE: CONFIG_CONSTANTS.BG_LOGO_CLIENT_SIZE,
  LOGO_ADMIN_SIZE: CONFIG_CONSTANTS.DOCSNOW_LOGO_ADMIN_SIZE,
  CLIENT_NOTES_DISPLAY_NAME: CONFIG_CONSTANTS.BG_CLIENT_NOTES_DISPLAY_NAME,
};
//payment static strings
export var STRIPE_PAYMENT_SETTINGS: any = {
  STRIPE_PAY_API_KEY:
    "pk_test_51M6So2SEBDAvJi4vs1OL9pL8tuMiWgne6m7bqObVjyrbfOQ3pV9AJJhmUF2QJere9T4xpCwf2cS8TMv5oVEBz19q00HFjTVvrx",
  STRIPE_ID: "stripe-script",
  STRIPE_TYPE: "text/javascript",
  STRIPE_SRC: "https://checkout.stripe.com/checkout.js",
  STRIPE_LOCALE: "auto",
  NAME: "DocsNow",
  DESCRIPTION: "Sandbox",
  CURRENCY: "usd",
  IMAGE: "",
  EMAIL: "info@docsnow.io",
};
// ALL API URL's HTTP ACTIONS
export var API_URLS: any = {
  Generic_api_url: main_url.api_url,
  Generic_Identity_api_url: main_url.identity_api_url,
  Generic_Login_type: main_url.login_type,
  UploadDocument_url: "DocServiceRequest/uploaddocuments",
  GetTemplateDetails_url: "DocServiceRequest/renderdoctemplate",
  SendDocument_url: "DocServiceRequest/senddocrequest",
  GetAllDocsets_url: "DocSet/getalldocsets",
  DeleteDoc_url: "Admin/deletedocument",
  ProcessDocumentRequest_url: "DocServiceRequest/processdocrequests",
  ProcessNotification_url: "Notification/processnotifications",
  ResendNotification_url: "Notification/ResendNotificationEmail",
  downloadRequest_url: "DocServiceRequest/downloadzip",
  GetDocServiceRequest_url: "DocServiceRequest/getallrequests",
  SendBulk_url: "DocServiceRequest/senduploadfile",
  validateAndSubmit_url: "DocServiceRequest/validatedocservicerequest",
  userDocuments_url: "Admin/getalldocrequestdocuments",
  userDocumentsDownloads_url: "Admin/downloaddocument",
  getAllUsers_url: "Admin/getallusers",
  getDocuments_url: "Admin/searchbyfilter",
  getNotes_url: "DocServiceRequest/getdocnotesfordoctype",
  getClientNotes: "DocServiceRequest/GetClientNotes",
  getAdminNotes: "DocServiceRequest/GetAdminNotes",
  addClientNotes_url: "DocServiceRequest/addclientnotes",
  addAdminNotes_url: "DocServiceRequest/addadminnotes",
  approveStatus_url: "Admin/approvestatus",
  getDocDetails_url: "Admin/getdocdetails",
  getAccountDetails_url: "Profile",
  updateAccountDetails_url: "Profile/updateProfile",
  updateAccountPassword_url: "ForgotPassword/Profile/ChangePassword",
  ForgotPassword_url: "ForgotPassword/Profile/ForgotPassword",
  CreatePassword_url: "ForgotPassword/UpdateForgotPassword",
  ValidateForgotPassword_url: "ForgotPassword/ValidateForgotPassword",
  login_url: "Auth/login",
  getAllDocSet_url: "DocSet/getalldocsets",
  getAllDocGroup_url: "DocGroup/getalldocgroups",
  getAllDocType_url: "DocType/getalldoctypes",
  addDocSet_url: "DocSet/addDocSet",
  addDocGroup_url: "DocGroup/addDocGroup",
  addDocType_url: "DocType/addDocType",
  updateDocSet_url: "DocSet/UpdateDocSet",
  updateDocGroup_url: "DocGroup/UpdateDocGroup",
  updateDocType_url: "DocType/UpdateDocType",
  deleteDocSet_url: "DocSet/deleteDocSet",
  deleteDocGroup_url: "DocGroup/deleteDocGroup",
  deleteDocType_url: "DocType/deleteDocType",
  getAllRequestedBy: "Dashboard/GetAllRequestedBy",
  getEmailsListOfRequestor: "Dashboard/GetEmailsListOfRequestor",
  getRequestorDashboard: "Dashboard/GetRequestDashboard",
  getDocumentDashboard: "Dashboard/GetDashboardFilterValues",
  setNotificationTemplateUrl: "NotificationTemplate/assignDefaultTemplate",
  getTemplates: "NotificationTemplate/getTemplates",
  setDefaultTemplateForAll: "DocSet/setDefaultTemplateForDocSets",
  getPermissionsByProjCode: "Permission/getPermByProjCode",
  getPermissionsByProjName: "Permission/getPermByProjName",
  getPermByModCode: "Permission/getPermByModCode",
  permissions: "Permission/",
  freeTrialRegister: "Auth/freetrialregistration",
  checkPasswordExpiry: "ForgotPassword/checkPasswordExpiry",
  onBoardUserUrl: "Auth/onboarduser",
  resendVerificationCode: "Auth/resendverificationcode",
  checkverificationcode: "Auth/checkverificationcode",
  onboardedsignin: "Auth/onboardedsignin",
  sendReminder: "DocServiceRequest/sendReminder",
  uploadTemplate: "NotificationTemplate/uploadTemplate",
  getTimeZone: "Auth/getTimeZone",
  getNotificationTemplateById:
    "NotificationTemplate/GetNotificationTemplateById",
  updateNotificationTemplate: "NotificationTemplate/updateNotificationTemplate",
  getAllTemplates: "NotificationTemplate/getAllTemplates",
  getdocgroupdetailsbydocsetid: "DocGroup/getdocgroupdetailsbydocsetid",
  downloadsampledoctypetemplate:
    "DocServiceRequest/downloadsampledoctypetemplate",
  defaultTemplateOnBoarding: "NotificationTemplate/defaultTemplateOnBoarding",
  deletenotificationtemplate: "NotificationTemplate/deletenotificationtemplate",
  archivenotificationtemplate:
    "NotificationTemplate/archivenotificationtemplate",
  archiveDocSet_url: "DocSet/archiveDocSet",
  archiveDocGroup_url: "DocGroup/archiveDocGroup",
  archiveDocType_url: "DocType/archiveDocType",
  updateExpiryDate: "DocServiceRequest/updateexpirydate",
  reopenDocServiceRequest: "DocServiceRequest/reopen",

  getAllRecipients: "RecipientList/getallrecipientlist",
  getRecipientList: "RecipientList/getallrecipientlist",
  addRecipient: "RecipientList/addrecipientlist",
  updateRecipient: "RecipientList/updaterecipientlist",
  archiveRecipient: "RecipientList/archiverecipientlist",
  deleteRecipient: "RecipientList/deleterecipientlist",
  importRecipient: "RecipientList/uploadrecipientlistfile",
  getAllGroups: "RecipientList/getallgroups",
  getDocsetEmails: "DocSetEmail/getalldocsetemail",
  addDocsetEmail: "DocSetEmail/adddocsetemail",
  updateDocsetEmail: "DocSetEmail/updatedocsetemail",
  archiveDocsetEmail: "DocSetEmail/archivedocsetemail",
  deleteDocsetEmail: "DocSetEmail/deletedocsetemail",
  docsetEmailsforDropdown: "DocSetEmail/getdocsetemailbydocsetid",
  importDocsetEmails: "DocSetEmail/uploaddocsetemailfile",
  getDocserviceRequestById: "DocServiceRequest/getdocservicerequest",
  createDocsetwithGroupsandTypes: "DocSet/addDocSetNew",
  updateDocsetwithGroupsandTypes: "DocSet/updateDocSetNew",
  getAllDocsetById: "DocSet/getdocsetbyid",
  agreeTermsConditions: "DocServiceRequest/updateagreetoterms",
  getAllValidDocsets: "DocSet/getallvaliddocsets",
  getAllImages: "Assets/getAllAssets",
  uploadImage: "Assets/addAssets",
  DownloadExcelFile: "RecipientList/DownloadExcelFile",
  uploadrecipientlistfile: "RecipientList/uploadrecipientlistfile",
  getGeneralSettings: "Settings/GetSettings",
  updateGeneralSettings: "Settings/UpdateSettings",
  GetBrandSettings: "Settings/GetBrandSettings",
  IsAuthEnabledForRequest: "DocServiceRequest/IsAuthEnabledForRequest",
  sendUserVerificationCode: "UserVerification/SendUserVerificationCode",
  reSendUserVerificationCode: "UserVerification/ReSendUserVerificationCode",
  OtpVerification: "UserVerification/OtpVerification",
  getAllFormsets: "FormSet/GetAllFormSets",
  createFormset: "FormSet/AddFormSet",
  updateFormset: "FormSet/UpdateFormSet",
  deleteFormset: "FormSet/DeleteFormSet",
  getFormsetById: "FormSet/GetFormSetByCode",
  formSetSubmission: "UserForm/UserFormSetSubmission",
  getFormsetNotes: "DocServiceRequest/GetFormNotesByCode",
  addFormsetNotes: "DocServiceRequest/AddFormSetNotes",
  getUserFormsByRequestId: "UserForm/GetUserFormsByDocRequestId",
  addFormSetAdminNotes: "DocServiceRequest/AddFormSetAdminNotes",
  updateFormsetStatus: "UserForm/UpdateUserFormSetStatus",
  // validateImage: "https://api.json-generator.com/templates/qyovMx1BOUsC/data",
  validateImage: "DocumentAI/classify-document",
  getAllSubscriptionPlans: "Subscriptions/GetAllSubscriptionPlans",
  getSubscriptionPlanDetailsById: "Subscriptions/GetParticularSubscriptionPlan",
  addTenantSubscriptions: "Subscriptions/AddTenantSubscriptions",
  getStorageInfo: "Subscriptions/GetUserStorageInfo",
  addTransaction: "PaymentTransaction/AddTransactionDetails",
  getTenantSubscriptionPlan: "Subscriptions/GetTenantSubscriptionPlans",
  getAllPaymentProviders: "PaymentProvider/GetAllPaymentProvidersDetails",
  downloadDocumentForPreview: "DocServiceRequest/downloaddocument",
  addEsignature: "DocServiceRequest/addESignature",
  documentPreviewUrl:
    "https://bg-docsnow-api-dev.baygrape.com/api/Admin/DocumentPreview/",
  getAllESignatureTemplates: "ESignatureTemplate/GetESignatureTemplate",
  addESignatureTemplate: "ESignatureTemplate/AddESignatureTemplate",
  getESignTemplateDetailsById: "ESignatureTemplate/EditESignatureTemplate",
  updateESignatureTemplate: "ESignatureTemplate/UpdateESignatureTemplates",
  getAllESignatureTemplatesDropdown:
    "ESignatureTemplate/GetTenantBasedESignatiureTemplates",
  duplicateDocset: "DocSet/addDuplicateDocSet",
};

export const HTTP_VERBS: any = {
  Get: "get",
  GetById: "getById",
  Post: "post",
  Update: "put",
  Delete: "delete",
  Post2: "post2",
  Get2: "get2",
};

export const RECAPTCHA_SITEKEY: any = {
  siteKey: BG_CONFIG.Qa_siteKey,
};
